import React, { useState, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { css } from '@emotion/react'
import io from 'socket.io-client'

import Header from '../components/header'
import Menu from '../components/menu'
import Calendars from '../components/calendars'
import Products from '../components/products'
import Markets from '../components/markets'
import Dashboard from '../components/dashboard'
import Departments from '../components/departments'
import Roles from '../components/roles'
import Agents from '../components/agents'
import Sellouts from '../components/sellouts'
import Reports from '../components/reports'
import Depara from '../components/depara'
import Rules from '../components/rules'
import Csv from '../components/csv'

import './styles.css'

const Main = () => {
  const [selectedMenu, setSelectedMenu] = useState([])
  const [page, setPage] = useState('dashboard')
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const loadPage = () => {
    switch (page.mnuLink) {
      case 'dashboard':
        return <Dashboard setLoading={setLoading} selectedMenu={selectedMenu} isOpen={isOpen} />
      case 'calendars':
        return <Calendars setLoading={setLoading} />
      case 'departments':
        return <Departments setLoading={setLoading} />
      case 'reports':
        return <Reports setLoading={setLoading} />
      case 'depara':
        return <Depara setLoading={setLoading} />
      case 'rules':
        return <Rules setLoading={setLoading} />
      case 'csv':
        return <Csv setLoading={setLoading} />
      case 'products':
        return <Products setLoading={setLoading} />
      case 'markets':
        return <Markets setLoading={setLoading} />
      case 'roles':
        return <Roles setLoading={setLoading} />
      case 'agents':
        return <Agents setLoading={setLoading} />
      case 'sellouts':
        return <Sellouts setLoading={setLoading} />
      default:
        return <div />
    }
  }

  useEffect(() => {
    loadPage()
  }, [page])

  useEffect(() => {
    // const registerSocket = () => {
    //   console.log('registerSocket')
    //   const socket = io('https://imp.appsellout.com.br')
    //   console.log(socket)
    //   socket.on('connect', (client) => {
    //     console.log(client)
    //     console.log(socket.id)
    //   })
    //   socket.on('disconnect', (client) => {
    //     console.log(client)
    //     console.log(socket.id)
    //   })
    //   socket.on('status', (data) => {
    //     console.log(data)
    //   })
    // }
    // registerSocket()
  }, [])

  return (
    <div className="mainstyle">
      <div className="header">
        <Header />
      </div>
      <div className="midstyle">
        <div className="menubar">
          <Menu setPage={setPage} setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} setIsOpen={setIsOpen} />
        </div>
        <div className="pagestyle">
          {loadPage()}
        </div>
      </div>
      <div className="sweet-loading">
        <PulseLoader
          css={css`
              position: absolute;
              top: calc(100vh - 50vh);
              left: calc(100vw - 50vw);
              z-index: 1000;
              width: 200px;
              display: block;
              margin: 0 auto;
              border-color: #000;
              /* background-color: #606884; */
              &:hover {
                color: red;
              }
            `}
          size={30}
          color="#f77"
          loading={loading}
        />
      </div>
    </div>
  )
}

export default Main
