import apii from '../../../../services/api_import'

const markets = async (year, month) => {
  if ((year === '') || (month === '')) return []
  const datenow = new Date()
  datenow.setMonth(datenow.getMonth() - 1)
  // const mm = datenow.getMonth() + 1
  // console.log('MES ATUAL: ', mm)
  const result = await apii.get(`analytic/markets?year=${year}&month=${month}`)
  // console.log('Markets: ', result)
  const { data } = result
  return data
}

export default markets
