/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

export const ContainerStyle = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #000;
  width: 100%;
  height: 100%;
  bottom: 0;
`
export const ContentStyle = styled.div`
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 14px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #000;
  /* width: 100%; */
  height: 100%;
  bottom: 0;
`
export const AuxStyle = styled.div`
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 14px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #000;
  width: 400px;
  height: 100%;
  bottom: 0;
`
export const HeaderStart = styled.div`
  width: 80px;
  padding-top: 5px;
  text-align: center;
`
export const DataStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100vh - 140px);
`
export const EditStyle = styled.div`
  margin-top: 10px;
  margin-left: 5px;

  /* width: 100%; */
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
export const SummaryStyle = styled.div`
  margin-top: 10px;
  margin-left: 5px;
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
export const GridStyle = styled.div`
  /* margin-top: 10px; */
  margin-right: 5px;
  padding-top: 4px;
  /* width: 100%; */
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
export const RowStyle = styled.div`
  display: flex;
  /* width: 100%; */
  background: #1F2228;
  flex: 1;
  flex-direction: row;
  border: 4px solid #1F2228;
  /* margin-top: 4px; */
  margin-bottom: 4px;
  // margin-left: 4px;
  // margin-right: 4px;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 10px; */
  :hover {
    background: #000;
    color: #f5f5f5;
    /* width: 90%; */
    border-radius: 0px 40px 40px 0px;
    border-left: 4px solid #1F2228;
  }
`
export const LeftStyle = styled.div`
  display: flex;
  /* width: 100%; */
  flex: 1;
  flex-direction: row;
  border: 2px solid #1F2228;
  /* margin-top: 4px; */
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background: #1F2228;
  color: #f5f5f5;
  /* width: 90%; */
  border-radius: 0px 40px 40px 0px;
  border-left: 2px solid #1F2228;
  max-width: 400px;
  min-width: 220px;
  flex-wrap: wrap;
  
`
export const CircleStyle = styled.div`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: #2E313B;
  color: #1F2228;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-clip: padding-box;
  background-size: cover;
`
export const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1F2228;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
`
export const AgentStyle = styled.div`
  display: 'flex';
  flexDirection: 'column';
  justifyContent: 'flex-start';
  padding-left: 10px;
  flex: 1;
`
export const TitleStyle = styled.div`
  padding-left: 10px;
  flex: 1;
`
export const PlusStyle = styled.div`
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: none;
`
export const TableStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
`
export const ListStyle = styled.ul`
  background: #000;
  width: 400px;
`
export const UlStyle = styled.ul`
  /* background: #1F2228; */
  /* padding-top: 20px; */
  cursor: pointer;
  width: 100%;
  overflow-y: auto;
  /* margin: 5px; */
  ::-webkit-scrollbar-track {
    webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #1F2228;
  }
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #1F2228;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000;
    border: 2px solid #555555;
  }
`
export const UlContactStyle = styled.ul`
  /* background: #1F2228; */
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
  height: calc(100vh - 360px);
  overflow-y: auto;
  margin: 5px;
  border: 1px solid #606884;
`
export const LiStyle = styled.li`
  /* border-bottom: 1px solid #000; */
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  /* margin-left: 5px; */
  margin-top: 5px;
  :active {
    /* background: #2f3; */
  }
  div {
    flex: 1;
  }
`
export const Form = styled.div`
  display: flex;
  background: #1F2228;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  border: 10px;
  padding: 10px;
`
export const Label = styled.label`
  font-size: 14px;
  color: #606884;
`
export const Input = styled.input`
  border: 1px solid #606884;
  width: 100%;
  height: 38px;
  font-size: 18px;
  border-radius: 4px;
  padding: 5px 10px;
  background: #000;
  color: #fafafa;
`
export const InputArea = styled.textarea`
  border: 1px solid #303236;
  width: 100%;
  font-size: 14px;
  font-family: 'Nunito' !important;
  border-radius: 4px;
  padding: 5px 10px;
  background: #000;
  color: #fafafa;
`
export const Button = styled.button`
  width: 120px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #f5f5f5;
  background: inherit;
  :disabled {
    border: 1px solid #606884 !important;
    color: #606884 !important;
  }
`
export const GridButton = styled.button`
  border-radius: 50%;
  background: #2E313B;
  font-size: 18px;
  border: none;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: '1px solid #31343D' */
  :hover {
    /* background: #000; */
    color: #fafafa;
  }
`
export const UnreadNumber = styled.label`
  font-size: 18px;
  font-weight: bold;
  color: green;
`
export const ButtonBoxStyle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
`
export const RowBoxStyle = styled.div`
  display: flex;
  padding: 5px;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const DoubleColStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    padding: 0 10px;
  }
`
export const ColStyle = styled.div`
  flex: 1;
  justify-content: flex-end;
  margin-right: 10px;
`
export const LineTypeA = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`
export const LineTypeB = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: #606884;
`
export const AgentBox = styled.div`
  border: 1px solid #1f2228;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
`
export const LineTypeC = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #1F2228;
`
