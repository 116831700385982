import axios from 'axios'
import history from '../routes/history'

// const uri = 'https://api.femsa.sellout.one/'
const uri = 'https://api.appsellout.com.br/'
// const uri = 'http://appsellout.com.br:9191/'
const TOKEN_KEY = 'token'
const AGENT_KEY = 'currentAgent'

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const getAgent = () => JSON.parse(localStorage.getItem(AGENT_KEY))

const api = axios.create({
  baseURL: uri,
})

export const testToken = async () => {
  const result = await api.post('token')
  // console.log('auth: ', result)
  if (!result.data) {
    localStorage.removeItem('currentAgent')
    return history.push('/login')
  }
  return result.data
}

api.interceptors.request.use(async (config) => {
  const token = JSON.parse(getToken())
  if (token) {
    config.headers = {
      'x-auth-token': `Bearer ${token}`,
    }
  }
  return config
})

export default api
