import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../../../context/AuthContext'
import {
  HeaderStyle, LogoStyle, MidStyle, EndStyle,
} from '../../styles/header'
import { CircleStyle, ClearButton } from '../../styles/components'
import Prisma from '../../../assets/icone_64.png'
import Femsa from '../../../assets/logomarca.png'
import history from '../../../routes/history'
import { getAgent } from '../../../services/api'

const imgUrl = 'https://omnichannel.sfo2.digitaloceanspaces.com/Femsa/images/'

const Header = () => {
  const [agent, setAgent] = useState([])
  const { LogOut } = useContext(Context)

  const reloadAgent = async () => setAgent(await getAgent())

  useEffect(() => {
    if (!agent || agent.length === 0) {
      reloadAgent()
    }
  }, [agent])

  function handleLogout() {
    if (window.confirm('Deseja sair da aplicação?')) {
      LogOut()
      return history.push('/login')
    }
    return null
  }

  return (
    <HeaderStyle>
      <LogoStyle style={{ justifyContent: 'flex-start', paddingLeft: '20px' }}>
        <img src={Prisma} alt="Prisma Network" />
      </LogoStyle>
      <MidStyle style={{ paddingRight: '20px' }}>
        <img src={Femsa} alt="Femsa" height={50} />
      </MidStyle>
      <EndStyle>
        <ClearButton
          type="button"
          onClick={() => handleLogout()}
          style={{ width: '250px' }}
        >
          <CircleStyle>
            <img
              src={imgUrl.concat(agent.agtPicture)}
              alt={agent.agtName}
              height={40}
              style={{ borderRadius: '50%', border: '1px solid #000' }}
            />
          </CircleStyle>
          <b style={{ paddingLeft: '10px' }}>{agent.agtName}</b>
        </ClearButton>
      </EndStyle>
    </HeaderStyle>
  )
}

export default Header
