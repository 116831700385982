/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import api from '../../../services/api'

import {
  ListStyle,
  DataStyle,
  GridStyle,
  UlStyle,
  RowStyle,
  CircleStyle,
  PlusStyle,
  LiStyle,
  LineTypeA,
  LineTypeB,
} from '../../styles/menu'

const Menu = ({ setSelectedMenu, setPage, setIsOpen }) => {
  const [menus, setMenus] = useState([])
  const [msgColor, setMsgColor] = useState('#D03B43')
  const [message, setMessage] = useState('')
  const [menuWidth, setMenuWidth] = useState(70)
  const { LogOut } = useContext(Context)
  const agtColor = ''

  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    setTimeout(() => {
      setMessage('')
    }, 1500)
  }

  useEffect(() => {
    const getMenus = async () => {
      try {
        popUp('Carregando registros.', 'info')
        const response = await api.get('menus')
        const result = response.data
        // console.log('getMenus: ', result)
        setMenus(result)
      } catch (error) {
        console.log(error)
        LogOut()
      }
    }
    getMenus()
  }, [])

  const redrawBorder = (id) => {
    menus.forEach((menu) => {
      if (id !== menu._id) {
        const element = document.getElementById(menu._id)
        element.style.borderLeft = '4px solid #1F2228'
      }
    })
  }
  const handleMenu = (menu) => {
    // console.log('handleMenu: ', menu)
    const element = document.getElementById(menu._id)
    element.style.borderLeft = `4px solid ${menu.mnuColor}`
    redrawBorder(menu._id)
    menus.map((mnu) => {
      if (mnu._id === menu._id) {
        document.getElementById(mnu._id).style.borderRight = '4px solid red' // #1F2228
      } else {
        document.getElementById(mnu._id).style.borderRight = '4px solid #1F2228'
      }
      return mnu
    })
    setSelectedMenu(menu)
    setPage(menu)
  }

  const handleSize = () => {
    if (menuWidth === 300) {
      setIsOpen(false)
      setMenuWidth(70)
    } else {
      setIsOpen(true)
      setMenuWidth(300)
    }
  }

  return (
    <ListStyle name="ListStyle" style={{ width: menuWidth }}>
      <PlusStyle style={{
        height: '40px', flexDirection: 'row', padding: '0px 10px', justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <button type="button" onClick={handleSize} style={{ backgroundColor: '#000', height: 40, border: 'none' }}>
          <i className="fas fa-bars" style={{ color: '#fafafa', fontSize: '18px' }} />
        </button>
        {/* <Label style={{ color: msgColor }}>version 3.67</Label> */}
      </PlusStyle>
      <DataStyle name="DataStyle">
        <GridStyle name="GridStyle" style={{ flex: 2 }}>
          <UlStyle style={{ padding: '15px 10px', height: 'calc(100vh - 170px)' }}>
            {menus.map((mnu) => (
              <RowStyle
                name={mnu._id}
                key={mnu._id}
                id={mnu._id}
                onClick={() => handleMenu(mnu)}
                style={{ width: '280px', paddingLeft: '10px', borderRight: '4px solid #1F2228' }}
              >
                <CircleStyle style={{}}>
                  <i className={mnu.mnuIcon} style={{ color: mnu.mnuColor, fontSize: '18px' }} />
                </CircleStyle>
                <LiStyle>
                  <LineTypeA style={{ color: '#fafafa', paddingLeft: '10px' }}>
                    <b>{mnu.mnuDescription}</b>
                  </LineTypeA>
                  <LineTypeB>
                    <div
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flex: 2,
                      }}
                    >
                      <i
                        className="fas phone"
                        style={{ color: agtColor, fontSize: '12px', paddingRight: '5px' }}
                      />
                    </div>
                  </LineTypeB>
                </LiStyle>
              </RowStyle>
            ))}
          </UlStyle>
        </GridStyle>
      </DataStyle>
    </ListStyle>
  )
}

export default Menu
