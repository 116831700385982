export const years = [
  // { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  // { label: '2022', value: '2022' },
  // { label: '2023', value: '2023' },
]

export const monthsTxt = [
  { label: 'Janeiro', value: 'Jan' },
  { label: 'Fevereiro', value: 'Fev' },
  { label: 'Março', value: 'Mar' },
  { label: 'Abril', value: 'Abr' },
  { label: 'Maio', value: 'Mai' },
  { label: 'Junho', value: 'Jun' },
  { label: 'Julho', value: 'Jul' },
  { label: 'Agosto', value: 'Ago' },
  { label: 'Setembro', value: 'Set' },
  { label: 'Outubro', value: 'Out' },
  { label: 'Novembro', value: 'Nov' },
  { label: 'Dezembro', value: 'Dez' },
]
export const months = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 },
]

export const days = [
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
  { label: '31', value: 31 },
]
