/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import lodash, { uniqueId } from 'lodash'
import uniqueID from 'lodash/uniqueId'
import * as d3 from 'd3-array'
// import crypto from 'crypto'
import { years, months } from '../../../../services/data'
import Markets from './markets'
import api from '../../../../services/api'
import apii from '../../../../services/api_import'
import { FullDate } from '../../../../services/utils'
import './styles.css'
import Sales from './sales'

const Summary = ({ setLoading, popUp, isOpen }) => {
  const datenow = new Date()
  // const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  const [markets, setMarkets] = useState([])
  const [marketsOptions, setMarketsOptions] = useState([])
  const [allMarkets, setAllMarkets] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [sellouts, setSellouts] = useState([])
  const [filtered, setFiltered] = useState([])
  const [totals, setTotals] = useState([])
  const [totalSales, setTotalSales] = useState([])
  const [filteredVersions, setFilteredVersions] = useState([])
  const [totalPonderado, setTotalPonderado] = useState([])
  const [summary, setSummary] = useState([])
  const [proSkuSearch, setProSkuSearch] = useState('')
  const [proEanSearch, setProEanSearch] = useState('')
  const [proDescriptionSearch, setProDescriptionSearch] = useState('')
  const mm = (datenow.getMonth() - 1 === 0) ? 12 : datenow.getMonth()
  const [lastday, setLastday] = useState(0)
  const [year, setYear] = useState(years[years.length - 1].value)
  const [month, setMonth] = useState(mm)
  const [versions, setVersions] = useState([])
  const [version, setVersion] = useState('0')
  const [keyCode, setKeyCode] = useState('')
  const [terCode, setTerCode] = useState('')
  const [clicked, setClicked] = useState(false)
  const [calendarUpdated, setCalendarUpdated] = useState('')
  const [calendarFile, setCalendarFile] = useState('')
  const [deparaUpdated, setDeparaUpdated] = useState('')
  const [deparaFile, setDeparaFile] = useState('')
  const [file, setFile] = useState('')
  const [fileId, setFileId] = useState('')

  const handleMarket = (e) => { setKeyCode(e) }
  const handleState = (e) => { setTerCode(e) }
  const handleYear = (e) => { setYear(e) }
  const handleMonth = (e) => { setMonth(e) }
  const handleVersion = (e) => { setVersion(e) }
  const process = async () => {
    if (year !== '' && month !== '' && keyCode !== '' && terCode !== '' && version !== '') {
      setLoading(true)
      const url = `analytic?year=${year}&month=${month}&keyCode=${keyCode}&terCode=${terCode}&version=${version}&test=0&proSkuPic=`
      // console.log('URL: ', url)
      const last = new Date(year, month, 0)
      const lastDay = last.getDate()
      // console.log('last: ', last)
      // console.log('lastDay: ', lastDay)
      setLastday(lastDay)
      const result = await apii.get(url)
      const { data } = result
      const lines = []
      data.forEach((row) => {
        const exists = lines.filter((line) => (line.proSkuPic === row.proSkuPic && line.terCode === row.terCode))
        if (exists.length > 0) {
          // adiciona campo
          lines.some((obj) => {
            // obj[row.selDay] = row.vendas
            for (let d = 1; d <= lastDay; d += 1) {
              if ((obj.proSkuPic === row.proSkuPic) && (d === row.selDay) && (obj.terCode === row.terCode)) {
                obj[d] = row.vendas
                obj.ponderado += row.ponderado
              }
            }
          })
        } else {
          for (let d = 1; d <= lastDay; d += 1) {
            // cria nova linha
            if (d === row.selDay) { row[d] = row.vendas } else { row[d] = 0 }
          }
          lines.push(row)
        }
        // console.log(row)
      })
      setSellouts(lines)
      setFiltered(lines)
      setLoading(false)
    }
    console.log(`${sellouts.length} linhas processadas.`)
  }

  const downloadCSV = (csvStr, filename) => {
    setLoading(true)
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = filename
    hiddenElement.click()
    setLoading(false)
  }
  const geraCsv = () => {
    if (filtered.length === 0) {
      popUp('Selecione a rede, ano, mês e versão de calendário para gerar o download.', 'error')
    } else {
      const filename = `${keyCode}_${terCode}_${year}_${month}_sellout.csv`
      const count = filtered.length
      const head = {}
      for (let x = 1; x <= lastday; x += 1) {
        head[x] = 0
      }
      let vendas = 0
      let row = 'Território;SKU;Produto;SKU Pic;EAN;Calendário;Início;Fim;'
      for (let i = 1; i <= lastday; i += 1) {
        row += `Dia ${i};`
      }
      let ponderadototal = 0
      row += 'Sellout;Ponderado\n'
      filtered.forEach((line) => {
        let totalRow = 0
        let headtotal = 0
        ponderadototal += line.ponderado
        row += `${line.terCode};${line.proSku};${line.proDescription};${line.proSkuPic}";${line.proEan};${line.keyCalendar};${line.calStartDay};${line.calEndDay};`
        for (let i = 1; i <= lastday; i += 1) {
          const sells = lodash.isNumber(line[i]) ? Math.round(parseFloat(line[i]), 2) : 0.0
          head[i] += line[i]
          totalRow += sells
          row += `${sells};`
          headtotal += line[i]
        }
        const ponderado = lodash.isNumber(line.ponderado) ? Math.round(line.ponderado, 2) : 0.0
        row += `${totalRow};${ponderado}\n`
        vendas += headtotal
      })
      row += `${count};${count};${count};${count};${count};${count};`
      for (let j = 1; j <= lastday; j += 1) {
        row += `${head[j]};`
      }
      row += `${vendas};${ponderadototal}\n`
      downloadCSV(row, filename)
    }
  }

  // const getVersions = async (m) => {
  //   const M = monthNames[m - 1]
  //   const result = await api.get(`dashboard/versions?year=${year}&month=${M}`)
  //   console.log('VERSIONS: ', result.data)
  //   if (result.data.length > 0) {
  //     setVersions(result.data)
  //     if (result.data[0]) {
  //       setVersion(result.data[0].calVersion)
  //       console.log('calVersion: ', result.data[0].calVersion)
  //       console.log('FILEID: ', result.data[0].fileId)
  //       setFileId(result.data[0].fileId)
  //     }
  //   }
  // }
  const getVersions = async () => {
    const result = await api.get('dashboard/versions')
    // console.log('VERSIONS: ', result.data)
    if (result.data.length > 0) {
      setVersions(result.data)
      // console.log('VERSIONS: ', result.data)
      if (result.data[0]) {
        setVersion(result.data[0].calVersion)
        // console.log('FILEID: ', result.data[0].fileId)
        setMonth(result.data[0].calMonth)
        setFileId(result.data[0].fileId)
      }
    }
  }

  const filterVersions = () => {
    const filter = versions.filter((ver) => ver.calYear === parseInt(year, 10) && ver.calMonth === parseInt(month, 10))
    if (filter.length > 0) {
      setFilteredVersions(filter)
      setVersion(filter[0] && filter[0].calVersion)
      setFileId(filter[0] && filter[0].fileId)
    }
  }
  const getStates = async (e) => {
    if (e) {
      setLoading(true)
      // console.log('key: ', e)
      // setLoading(true)
      // console.log('getStates: ', allMarkets)
      const territories = allMarkets.filter((mkt) => mkt.keyCode === e.toString())
      // const result = await api.get(`dashboard/states?keyCode=${e}`)
      // console.log('territory: ', territories)
      // const { data } = result
      const sta = []
      sta.push({
        label: 'Todos',
        value: '0000',
        key: '0000',
      })
      if (territories) {
        territories.map((key) => {
          sta.push({
            label: key.terCode,
            value: key.terCode,
          })
        })
      }
      // console.log('getStates: ', sta)
      // setStates(territories)
      setStateOptions(sta)
      if (sta.length === 0) {
        popUp('Nenhum registro encontrado', 'warning')
      } else {
        setTerCode(sta[0].value)
        popUp(`${stateOptions.length - 1} território(s) encontrado(s).`, 'success')
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    const getMarkets = async () => {
      setLoading(true)
      if ((year === '') || (month === '')) setMarkets([])
      // console.log('year: ', year)
      // console.log('month: ', month)
      const data = await Markets(year, month)

      const mkt = []
      if (data) {
        data.map((key) => {
          mkt.push({
            label: key.keyDescription,
            value: key.keyCode,
            key: uniqueID(),
            // key.keyCode.concat(key.terCode),
          })
        })
      }
      // console.log(mkt)
      // console.log(mkt[0])
      setMarketsOptions(mkt)
      setMarkets(data)
      const all = await apii.get(`keyaccounts?year=${year}&month=${mm}`)
      setAllMarkets(all.data)
      // console.log('allMarkets: ', all.data)
      if (mkt.length === 0) {
        popUp('Nenhum registro encontrado', 'warning')
      } else {
        setKeyCode(mkt[0].value)
        popUp(`${mkt.length} redes encontradas...`, 'success')
      }
      setLoading(false)
    }

    getMarkets()
    getStates()
    getVersions()
    filterVersions()
  }, [year, month])

  useEffect(() => {
    getStates(keyCode)
    filterVersions()
  }, [keyCode])

  useEffect(() => {
    const getFile = async () => {
      setLoading(true)
      const versionfiltered = versions.filter((ver) => ver.calVersion === version)
      // console.log('versionFiltered: ', versionfiltered)
      if (versionfiltered.length === 0) return []
      const id = versionfiltered[0].fileId
      // console.log('GETFILEID: ', id)
      setFileId(id)
      if (id) {
        const result = await api.get(`dashboard/file?fileId=${id}`)
        const fil = result.data
        // console.log('GETFILE: ', fil)
        setCalendarFile(result.data[0].filName)
        const data = FullDate(result.data[0].updatedAt)
        setCalendarUpdated(data)
        // console.log(data)
        setLoading(false)
        return result
      }
      setLoading(false)
      return []
    }
    getFile()
  }, [version])

  useEffect(() => {
    const getFile = async () => {
      // console.log('GETFILE: ', fileId)
      if (fileId) {
        setLoading(true)
        const result = await api.get(`dashboard/file?fileId=${fileId}`)
        const fil = result.data
        // console.log('GETFILE: ', fil)
        setCalendarFile(result.data[0].filName)
        const data = FullDate(result.data[0].updatedAt)
        setCalendarUpdated(data)
        // console.log(data)
        setLoading(false)
        return result
      }
      setLoading(false)
      return []
    }
    getFile()
  }, [fileId])

  useEffect(() => {
    const getDepara = async () => {
      // console.log('FILE: ', fileId)
      if (fileId) {
        setLoading(true)
        const result = await apii.get(`files/depara?type=depara&year=${year}&month=${month}`)
        // console.log('getDepara: ', result)
        if (result.data.length > 0) {
          setDeparaFile(result.data[0].filKey)
          const data = FullDate(result.data[0].updatedAt)
          setDeparaUpdated(data)
          // console.log(data)
        }
        setLoading(false)
        return result
      }
      setLoading(false)
      return []
    }
    getDepara()
  }, [calendarUpdated])

  useEffect(() => {
    const summarize = () => {
      setLoading(true)
      const sums = []
      const line = {
        skutotal: filtered.length,
        produtototal: filtered.length,
        skupictotal: filtered.length,
        eantotal: filtered.length,
        calendariototal: filtered.length,
      }
      for (let x = 1; x <= lastday; x += 1) {
        line[x] = 0
      }
      let ponderado = 0
      let vendas = 0
      filtered.forEach((sel) => {
        // console.log(sel)
        ponderado += sel.ponderado
        let headtotal = 0
        for (let x = 1; x <= lastday; x += 1) {
          line[x] += sel[x]
          headtotal += sel[x]
        }
        vendas += headtotal
        // const result = sums.filter((s) => s.selDay === sel.selDay)
        // if (result.length > 0) {
        //   // soma o resultado

        //   sums.some((obj) => {
        //     if (obj.selDay === sel.selDay) {
        //       obj.vendas += sel.vendas
        //       obj.ponderado += sel.ponderado
        //       return true
        //     }
        //   })
        // } else {
        //   // cria um novo registro
        //   const data = {
        //     selDay: sel.selDay,
        //     vendas: sel.vendas,
        //     ponderado: sel.ponderado,
        //   }
        //   sums.push(data)
        // }
      })
      // console.log(sums)
      line.ponderado = ponderado
      line.vendas = vendas
      setSummary(line)
      const sumSellout = d3.sum(sums, (d) => d.vendas)
      const sumPonderado = d3.sum(sums, (d) => d.ponderado)
      setTotalSales(sumSellout)
      setTotalPonderado(sumPonderado)
      setTotals(sums)
      setLoading(false)
    }
    summarize()
  }, [filtered])

  const Header = () => {
    const rows = []
    const headercolumns = []
    const l = new Date(year, month, 0)
    const ld = l.getDate()
    for (let i = 1; i <= ld; i += 1) {
      headercolumns.push(<div key={uniqueID()} className="column" style={{ width: '70px', textAlign: 'center' }}>{`Dia ${i}`}</div>)
    }
    // console.log('headercolumns: ')
    const headerLine = (
      <div key={uniqueId()} className="header">
        <div className="column" style={{ width: '80px', textAlign: 'center' }}>Território</div>
        <div className="column" style={{ width: '80px', textAlign: 'center' }}>SKU</div>
        <div className="column" style={{ width: '400px', textAlign: 'left' }}>Produto</div>
        <div className="column" style={{ width: '300px', textAlign: 'left' }}>SKU Pic</div>
        <div className="column" style={{ width: '200px', textAlign: 'left' }}>EAN</div>
        <div className="column" style={{ width: '100px', textAlign: 'left' }}>Calendário</div>
        <div className="column" style={{ width: '80px', textAlign: 'left' }}>Início</div>
        <div className="column" style={{ width: '80px', textAlign: 'left' }}>Final</div>
        {headercolumns}
        <div className="column" style={{ width: '80px' }}>Sellout</div>
        <div className="column" style={{ width: '80px' }}>Ponderado</div>
      </div>
    )
    rows.push(headerLine)
    return rows
  }

  const Total = () => {
    const sumcolumns = []
    // const l = new Date(year, month, 0)
    // const ld = l.getDate()
    // console.log(summary)
    for (let x = 1; x <= lastday; x += 1) {
      sumcolumns.push(<div key={x} className="column" style={{ width: '70px', color: 'rgb(129, 129, 0)' }}>{summary[x]}</div>)
    }
    // for (let i = 1; i <= ld; i += 1) {
    //   const result = summary.filter((sel) => sel.selDay === i)
    //   if (result.length > 0) {
    //     // console.log('PRODUTO COM VENDA', result)
    //     const sells = lodash.isNumber(result[0].vendas) ? (Math.round(parseFloat(result[0].vendas) * 100) / 100) : 0.0
    //     console.log(sells)
    //     sumcolumns.push(<div key={i} className="column" style={{ width: '70px', color: 'rgb(129, 129, 0)' }}>{result[0].vendas}</div>)
    //   } else {
    //     sumcolumns.push(<div key={i} className="column" style={{ width: '70px', color: 'rgb(129, 129, 0)' }}>{0.0.toFixed(2)}</div>)
    //   }
    // }

    const sum = (
      <div className="soma">
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)', textAlign: 'center' }}>{`${summary.skutotal} itens`}</div>
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)', textAlign: 'center' }}>{`${summary.skutotal} itens`}</div>
        <div className="column" style={{ width: '400px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.produtototal} itens`}</div>
        <div className="column" style={{ width: '300px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.skupictotal} itens`}</div>
        <div className="column" style={{ width: '200px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.eantotal} itens`}</div>
        <div className="column" style={{ width: '100px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.calendariototal} itens`}</div>
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.calendariototal} itens`}</div>
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)', textAlign: 'left' }}>{`${summary.calendariototal} itens`}</div>
        {sumcolumns}
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)' }}>{summary.vendas}</div>
        <div className="column" style={{ width: '80px', color: 'rgb(129, 129, 0)' }}>{Math.round(parseFloat(summary.ponderado ? summary.ponderado : 0))}</div>
      </div>
    )
    return sum
  }

  const handleClear = () => {
    setLoading(true)
    setProSkuSearch('')
    setProEanSearch('')
    setProDescriptionSearch('')
    setFiltered(sellouts)
    setLoading(false)
  }

  const handleFiltered = () => {
    setLoading(true)
    let word = ''
    if (proSkuSearch.length > 0) {
      word = sellouts.filter((row) => row.proSku.toLowerCase().includes(proSkuSearch.toLowerCase()))
      // console.log(word)
      setFiltered(word)
    }
    if (proDescriptionSearch.length > 0) {
      word = sellouts.filter((row) => row.proDescription.toLowerCase().includes(proDescriptionSearch.toLowerCase()))
      // console.log('proDescription: ', word)
      setFiltered(word)
    }
    if (proEanSearch.length > 0) {
      word = sellouts.filter((row) => row.proEan.toLowerCase().includes(proEanSearch.toLowerCase()))
      // console.log('proEan: ', word)
      setFiltered(word)
    }
    setLoading(false)
  }

  return (
    <div id="summary" style={{ width: isOpen ? '760px' : '960px' }}>
      <div className="filters" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', paddingLeft: '5px', width: '100%',
        }}
        >
          <div className="selectHeader" style={{ width: '80px' }}>Ano</div>
          <div className="selectHeader" style={{ width: '130px' }}>Mês</div>
          <div className="selectHeader" style={{ width: '230px' }}>Rede</div>
          <div className="selectHeader" style={{ width: '80px' }}>Território</div>
          <div className="selectHeader" style={{ width: '80px' }}>Versão</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <select name="FilterSelect" onChange={(e) => handleYear(e.target.value)} value={year} style={{ width: '80px' }}>
            {years.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleMonth(e.target.value)} value={month} style={{ width: '130px' }}>
            {months.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>

          <select name="FilterSelect" onChange={(e) => handleMarket(e.target.value)} value={keyCode} style={{ width: '230px' }}>
            {marketsOptions.map((item, i) => (
              <option key={uniqueId()} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleState(e.target.value)} value={terCode} style={{ width: '80px' }}>
            {stateOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleVersion(e.target.value)} value={version} style={{ width: '80px' }}>
            {filteredVersions && filteredVersions.map((item) => (
              <option key={uniqueID()} value={item.calVersion}>
                {`v. ${item.calVersion}`}
              </option>
            ))}
          </select>
          <button disabled={clicked} type="button" onClick={process} className="buttonnav">OK</button>
        </div>
        <div className="infoRow">
          <strong className="infoCol1">{calendarFile}</strong>
          <strong className="infoCol2">{calendarUpdated}</strong>
        </div>
        <div className="infoRow">
          <strong className="infoCol1">{deparaFile}</strong>
          <strong className="infoCol2">{deparaUpdated}</strong>
        </div>
      </div>
      <div>
        <div className="preheader" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="filter">
            <div className="inputcolumn">
              <input
                className="inputSearch"
                placeholder="pesquisar Sku"
                value={proSkuSearch}
                onChange={(e) => setProSkuSearch(e.target.value)}
              />
            </div>
            <div className="inputcolumn">
              <input
                className="inputSearch"
                placeholder="pesquisar produto"
                value={proDescriptionSearch}
                onChange={(e) => setProDescriptionSearch(e.target.value)}
              />
            </div>
            <div className="inputcolumn">
              <input
                className="inputSearch"
                placeholder="pesquisar EAN"
                value={proEanSearch}
                onChange={(e) => setProEanSearch(e.target.value)}
              />
            </div>
            <div className="inputcolumn">
              <button className="buttonSearch" type="button" onClick={handleClear} disabled={clicked}>Limpar</button>
              <button className="buttonSearch" type="button" onClick={handleFiltered} disabled={clicked}>Pesquisar</button>
              <button className="buttonSearch" type="button" onClick={geraCsv} disabled={clicked}>Download</button>
            </div>
          </div>
        </div>
        <div id="paginate">
          <div className="list">
            <div className="preheader">
              <Header />
            </div>
            <div className="preheader">
              <Total />
            </div>
            <Sales sellouts={filtered} lastday={lastday} />
          </div>
          {/* <div className="sum">
          </div> */}
          <div className="preheader">
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Summary
