import React, { useState, useEffect, useRef } from 'react'
import uniqueID from 'lodash/uniqueId'

import api from '../../../services/api'
import apii, { uri } from '../../../services/api_import'
import { FullDate } from '../../../services/utils'
import { years, months } from '../../../services/data'
import './styles.css'
import '../styles.css'

export default function Totais({
  setLoading, popUp, logs,
}) {
  // controles
  const dt = new Date()
  const [year, setYear] = useState(dt.getFullYear().toString())
  const mmm = (dt.getMonth() - 1 === 0) ? 12 : dt.getMonth()
  const [month, setMonth] = useState(mmm)
  const [sended, setSended] = useState(false)
  const [clicked, setClicked] = useState(false)
  const messagesEndRef = useRef(null)
  let fileName = ''
  // celendário
  const [file, setFile] = useState('')
  const [fileId, setFileId] = useState('')
  const [updated, setUpdated] = useState('')
  // redes
  const [marketsOptions, setMarketsOptions] = useState([])
  const [allMarkets, setAllMarkets] = useState([])
  const [market, setMarket] = useState([])
  const [keyCode, setKeyCode] = useState('')
  // territórios
  const [stateOptions, setStateOptions] = useState([])
  const [terCode, setTerCode] = useState('')
  // versões
  const [version, setVersion] = useState('')
  const [versions, setVersions] = useState([])
  const [filteredVersions, setFilteredVersions] = useState([])
  // sellout
  const [filtered, setFiltered] = useState([])
  // de-para
  const [depara, setDepara] = useState('')
  const [updatedDepara, setUpdatedDepara] = useState('')

  // const getMonths = async (key, ter) => {
  //   // setLoading(true)
  //   popUp('Obtendo dados...', 'info')
  //   console.log('getMonth:', key)
  //   const query = `dashboard/months?keyCode=${key.length === 0 ? '0000' : key}&terCode=${ter}`
  //   const result = await api.get(query)
  //   console.log('Months: ', result)
  //   const { data } = result
  //   const meses = []
  //   if (data) {
  //     data.map((mes) => {
  //       meses.push({
  //         label: monthNames[mes.selMonth - 1],
  //         value: mes.selMonth,
  //       })
  //     })
  //   }
  //   console.log('getMonths: ', meses)
  //   console.log('mes: ', meses[0])
  //   setMonths(meses)
  //   if (meses.length === 0) {
  //     popUp('Nenhum registro encontrado', 'warning')
  //   } else {
  //     console.log('MES SELECIONADO: ', meses[0].value)
  //     setMonth(meses[0].value)
  //     popUp('Dados encontrados...', 'success')
  //   }
  //   setLoading(false)
  // }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const getMarkets = async () => {
    popUp('Obtendo dados...', 'info')
    const mm = month
    const result = await apii.get(`analytic/markets?year=${year}&month=${mm}`)
    const { data } = result
    const mkt = []
    mkt.push({
      label: 'Todos',
      value: '0000',
      key: '0000',
    })
    if (data) {
      data.map((key) => {
        mkt.push({
          label: key.keyDescription,
          value: key.keyCode,
          key: uniqueID(),
        })
      })
    }
    const all = await apii.get(`keyaccounts?year=${year}&month=${mm}`)
    setAllMarkets(all.data)
    setMarketsOptions(mkt)
    if (mkt.length === 0) {
      popUp('Nenhum registro encontrado', 'warning')
    } else {
      setMarket(mkt[0].value)
      setKeyCode(mkt[0].value)
      popUp('Dados encontrados...', 'success')
    }
  }

  const getDepara = async () => {
    if (fileId) {
      const result = await apii.get(`files/depara?type=depara&year=${year}&month=${month}`)
      if (result.data.length > 0) {
        setDepara(result.data[0].filKey)
        const data = FullDate(result.data[0].updatedAt)
        setUpdatedDepara(data)
      }
      return result
    }
    return []
  }

  const getFile = async () => {
    if (fileId) {
      const result = await api.get(`dashboard/file?fileId=${fileId}`)
      setFile(result.data[0].filName)
      const data = FullDate(result.data[0].updatedAt)
      setUpdated(data)
      return result
    }
    return []
  }

  const getStates = async (e) => {
    if (e) {
      const territories = allMarkets.filter((mkt) => mkt.keyCode === e.toString())
      const sta = []
      sta.push({
        label: 'Todos',
        value: '0000',
        key: '0000',
      })
      if (territories) {
        territories.map((key) => {
          sta.push({
            label: key.terCode,
            value: key.terCode,
          })
        })
      }
      setStateOptions(sta)
      if (sta.length === 0) {
        popUp('Nenhum registro encontrado', 'warning')
      } else {
        setTerCode(sta[0].value)
        popUp('Dados encontrados...', 'success')
      }
    }
  }

  const handleMarket = (e) => {
    if (e) {
      setMarket(e)
      const aux = e.split(' - ')[0]
      setKeyCode(aux)
      setFiltered([])
    }
  }

  const handleState = (ter) => {
    if (ter) {
      setTerCode(ter)
      setFiltered([])
    }
  }

  const handleMonth = (mm) => {
    if (mm) {
      setMonth(mm)
      setFiltered([])
    }
  }

  const handleVersion = (e) => {
    if (e) {
      setVersion(e)
      getFile()
      getDepara()
      setFiltered([])
    }
  }

  const downloadSellout = (downloadFile) => {
    if (!downloadFile) return
    popUp('Preparando download...', 'info')
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `${uri}files/${downloadFile}`
    hiddenElement.target = '_blank'
    const filename = `${uri}files/${downloadFile}`
    hiddenElement.download = filename
    hiddenElement.click()
    setLoading(false)
  }

  const downloadCSV = (csvStr, k, t, y, m) => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`
    hiddenElement.target = '_blank'
    const M = m.length < 2 ? `0${m}` : m
    const filename = `${y}_${M}_${k}_${t}.csv`
    hiddenElement.download = filename
    hiddenElement.click()
  }

  const process = async () => {
    if (sended) return
    setLoading(true)
    popUp('Filtrando dados...', 'info')
    setClicked(true)
    setSended(true)
    popUp('Preparando dados...', 'info')
    let lines
    const sellouts = []
    try {
      if (year) {
        if (month) {
          if (keyCode !== '') {
            if (keyCode === '0000') {
              try {
                const data1 = {
                  selYear: parseInt(year, 10),
                  selMonth: parseInt(month, 10),
                  calVersion: version,
                }
                popUp('Solicitando sellout...', 'info')
                const result = await apii.post('sellouts/all', data1)
                const { data } = result
                popUp('Processando sellout...', 'info')
                if (data) {
                  const firstline = data.split('\n')
                  // eslint-disable-next-line prefer-destructuring
                  fileName = firstline[0]
                  const selloutAll = data.split('\n').slice(1).join('\n')
                  lines = selloutAll.split(/\r?\n/)
                  sellouts.push(selloutAll)
                }
              } catch (error) {
                console.log(error)
              }
            } else {
              try {
                const data2 = {
                  selYear: parseInt(year, 10),
                  selMonth: parseInt(month, 10),
                  keyCode,
                  terCode,
                  calVersion: version,
                }
                popUp('Solicitando sellout...', 'info')
                const result = await apii.post('sellouts/byka', data2)
                lines = result.data.split(/\r?\n/)
                sellouts.push(result.data)
              } catch (error) {
                console.log(error)
              }
            }
            const rows = []
            if (lines) {
              lines.forEach((line) => {
                rows.push(line)
              })
            }
            setFiltered(rows)
            if (sellouts) {
              if (sellouts.length === 0) {
                popUp('Nenhum registro encontrado', 'warning')
              } else if (keyCode === '0000') {
                downloadSellout(fileName)
                popUp('Dados encontrados...', 'success')
              } else {
                downloadCSV(sellouts, keyCode, terCode, year, month)
                popUp('Dados encontrados...', 'success')
              }
            }
          }
        }
      }
      setLoading(false)
      setClicked(false)
    } catch (error) {
      console.log(error)
      popUp(error, 'error')
      setLoading(false)
    } finally {
      setSended(false)
      setLoading(false)
      setClicked(false)
    }
  }

  const clearFile = () => {
    popUp('Limpando dados..', 'info')
    setFile('')
    setUpdated('')
    setDepara('')
    setUpdatedDepara('')
  }

  useEffect(() => {
    clearFile()
    popUp('Buscando calendário...', 'info')
    getFile()
    popUp('Buscando depara...', 'info')
    getDepara()
  }, [fileId])

  useEffect(() => {
    clearFile()
    getStates(market)
    const filter = versions.filter((ver) => ver.calYear === parseInt(year, 10) && ver.calMonth === parseInt(month, 10))
    if (filter.length > 0) {
      setFilteredVersions(filter)
      setVersion(filter[0] && filter[0].calVersion)
      setFileId(filter[0] && filter[0].fileId)
    }
    popUp('Buscando calendário...', 'info')
    getFile()
    popUp('Buscando depara...', 'info')
    getDepara()
  }, [market])

  useEffect(() => {
    clearFile()
    popUp('Buscando calendário...', 'info')
    getFile()
    popUp('Buscando depara...', 'info')
    getDepara()
  }, [terCode])

  useEffect(() => {
    clearFile()
    popUp('Atualizando versões...', 'info')
    const filter = versions.filter((ver) => ver.calYear === parseInt(year, 10) && ver.calMonth === parseInt(month, 10))
    if (filter.length > 0) {
      setFilteredVersions(filter)
      setVersion(filter[0] && filter[0].calVersion)
      setFileId(filter[0] && filter[0].fileId)
    }
    popUp('Buscando redes...', 'info')
    getMarkets()
    popUp('Buscando territórios...', 'info')
    getStates()
  }, [month])

  useEffect(() => {
    clearFile()
    popUp('Atualizando versões...', 'info')
    const filter = versions.filter((ver) => ver.calYear === parseInt(year, 10) && ver.calMonth === parseInt(month, 10))
    setFilteredVersions(filter)
    setVersion(filter[0] && filter[0].calVersion)
    setFileId(filter[0] && filter[0].fileId)
  }, [year])

  useEffect(() => {
    scrollToBottom()
  }, [logs])

  useEffect(() => {
    const getVersions = async () => {
      const result = await api.get('dashboard/versions')
      if (result.data.length > 0) {
        setVersions(result.data)
        if (result.data[0]) {
          setVersion(result.data[0].calVersion)
          setMonth(result.data[0].calMonth)
          setFileId(result.data[0].fileId)
        }
      }
    }
    popUp('Buscando versões...', 'info')
    getVersions()
    clearFile()
    popUp('Buscando redes...', 'info')
    getMarkets()
    popUp('Buscando calendário...', 'info')
    getFile()
    popUp('Buscando depara...', 'info')
    getDepara()
  }, [])

  return (
    <div className="container">
      <div className="filters" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="filtersHeader">
          <div className="selectHeader" style={{ width: '80px' }}>Ano</div>
          <div className="selectHeader" style={{ width: '120px' }}>Mês</div>
          <div className="selectHeader" style={{ width: '80px' }}>Versão</div>
          <div className="selectHeader" style={{ width: '240px' }}>Rede</div>
          <div className="selectHeader" style={{ width: '80px' }}>Território</div>
        </div>
        <div className="selectBox">
          <select name="FilterSelect" onChange={(e) => setYear(e.target.value)} value={year} style={{ width: '80px' }}>
            {years.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleMonth(e.target.value)} value={month} style={{ width: '120px' }}>
            {months.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleVersion(e.target.value)} value={version} style={{ width: '80px' }}>
            {filteredVersions && filteredVersions.map((item) => (
              <option key={item.calVersion} value={item.calVersion}>
                {`v. ${item.calVersion}`}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleMarket(e.target.value)} value={keyCode} style={{ width: '240px' }}>
            {marketsOptions.map((item) => (
              <option key={item.key} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select name="FilterSelect" onChange={(e) => handleState(e.target.value)} value={terCode} style={{ width: '80px' }}>
            {stateOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <button
            disabled={clicked}
            type="button"
            onClick={process}
            className="buttonnav"
            style={{ display: clicked ? 'none' : 'block' }}
          >
            OK
          </button>
        </div>
        <div className="infoRow" style={{ paddingLeft: '10px' }}>
          <strong className="infoCol1">{file}</strong>
          <strong className="infoCol2">{updated}</strong>
        </div>
        <div className="infoRow" style={{ paddingLeft: '10px' }}>
          <strong className="infoCol1">{depara}</strong>
          <strong className="infoCol2">{updatedDepara}</strong>
        </div>
      </div>
      <div className="datatable" style={{ height: 'calc(100vh - 350px)' }}>
        <div className="filterheader">
          {/* <input value={searchStore} onChange={(e) => handleFilterStore(e.target.value)} className="searchInput" placeholder="Procurar loja" />
          <input value={searchDay} onChange={(e) => handleFilterDay(e.target.value)} className="searchInput" placeholder="Procurar dia" />
          <input value={searchEan} onChange={(e) => handleFilterEan(e.target.value)} className="searchInput" placeholder="Procurar Ean" />
          <button type="button" onClick={clearFilter} className="buttonnav">Limpar</button> */}
          {/* <div style={{ width: '80px' }}>
          </div>
          <div style={{ width: '80px' }}>
          </div>
          <div style={{ width: '150px' }}>
          </div>
          <div style={{ width: '80px', textAlign: 'right' }}>
          </div> */}
        </div>
        <div
          className="tablebody"
          style={{
            height: 'calc(100vh - 390px)', flexDirection: 'column-reverse', paddingLeft: '30px',
          }}
        >
          {
            filtered.map((sell) => (
              <p key={uniqueID()}>{sell}</p>
            ))
          }
          <div />
        </div>
      </div>
      <div className="status">
        <ul>
          {
            logs.map((sts) => <p key={uniqueID()}>{sts.message}</p>)
          }
          <div ref={messagesEndRef} />
        </ul>
      </div>
    </div>
  )
}
