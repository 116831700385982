import React, { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'

import api from '../../../services/api'
import apio from '../../../services/api_import'
import '../styles.css'
import { years, months } from '../../../services/data'
import { FullDate } from '../../../services/utils'

const Calendar = ({ setLoading }) => {
  const dt = new Date()
  const [year, setYear] = useState(dt.getFullYear())
  const [month, setMonth] = useState(dt.getMonth())
  const [reload, setReload] = useState(false)
  const [files, setFiles] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [archive, setArchive] = useState('')
  const [archiveName, setArchiveName] = useState('')
  const [uploadedFile, setUploadedFile] = useState('')
  const [msgColor, setMsgColor] = useState('#000')
  const [message, setMessage] = useState('')
  let input = null

  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    // setTimeout(() => {
    //   setMessage('')
    // }, 5000)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    popUp(` Enviando o arquivo ${archiveName} para os servidores...`, 'info')
    const formData = new FormData()
    formData.append('file', archive)
    formData.append('type', 'calendar')
    formData.append('year', year)
    formData.append('month', month)

    try {
      const result = await apio.post('calendar/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // console.log(result)
      setUploadedFile(result.data)
      setLoading(false)
      popUp('Clique em IMPORTAR para finalizar a operação. Este processo pode levar cerca de um a dois minutos.', 'warning')
    } catch (error) {
      console.log(error)
      console.log('Erro ao gravar arquivo', error.response)
      popUp(error.response.data.result, 'error')
      setLoading(false)
    }
  }

  const handleSelect = (e) => {
    setUploadedFile(null)
    setArchive(e.target.files[0])
    setArchiveName(e.target.files[0].name)
  }

  const handleImport = async () => {
    setLoading(true)
    try {
      const filename = uploadedFile.filKey
      popUp(` Importando o arquivo ${filename} para o banco de dados...`, 'info')
      const id = uploadedFile._id
      const result = await apio.get(`imports/calendar?type=calendar&filename=${filename}&id=${id}&year=${year}&month=${month}`)
      // console.log(result.data.result)
      popUp(result.data.result, 'info')
      setUploadedFile(null)
      setArchiveName('')
      setReload(!reload)
      setLoading(false)
    } catch (error) {
      console.log(error)
      popUp('Error')
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   const dt = new Date()
  //   const mm = dt.getMonth() + 1
  //   const MM = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  //   const filMonth = MM.indexOf(mm)
  //   setMonth(filMonth)
  // }, [])

  useEffect(() => {
    const getFiles = async () => {
      const result = await api.get('files?type=calendar')
      if (result.data) {
        const docs = result.data
        // console.log(docs)
        setFiles(docs)
        const filter = docs.filter((file) => file.filYear === parseInt(year, 10) && file.filMonth === parseInt(month, 10))
        filter.sort((a, b) => ((a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)))
        setFilteredFiles(filter)
      }
    }
    getFiles()
  }, [reload])

  useEffect(() => {
    // console.log(year, month)
    const filter = files.filter((file) => file.filYear === parseInt(year, 10) && file.filMonth === parseInt(month, 10))
    filter.sort((a, b) => ((a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)))
    setFilteredFiles(filter)
  }, [year, files, month])

  // useEffect(() => {
  //   // console.log(year, month)
  //   const filter = files.filter((file) => file.filYear === parseInt(year, 10) && file.filMonth === parseInt(month, 10))
  //   filter.sort((a, b) => ((a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)))
  //   setFilteredFiles(filter)
  // }, [month])

  const renderRows = () => filteredFiles.map((arch) => (
    <tr key={uniqueId()} className="rows" style={{ fontSize: '12px' }}>
      <td className="rows">{arch.filName}</td>
      <td className="rows" width={80} style={{ textAlign: 'right' }}>{arch.filSize}</td>
      <td className="rows" width={150} style={{ textAlign: 'center' }}>{FullDate(arch.createdAt)}</td>
      {/* <td className="rows" width={100}>{arch.filName.includes('De_Para') ? 'De Para' : 'Arquivo de rede'}</td> */}
      <td className="rows" width={80} style={{ textAlign: 'center' }}>{arch.filMonth.toString().length === 1 ? `0${arch.filMonth}` : arch.filMonth}</td>
      <td className="rows" width={80} style={{ textAlign: 'center' }}>{arch.filVersion}</td>
    </tr>
  ))

  return (
    <div className="container">
      <div className="headertitle" style={{ width: '100%' }}>
        <div style={{ width: '50%' }}>
          <p>Calendário</p>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <form onSubmit={handleSubmit}>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', width: '250px',
          }}
          >
            <select name="FilterSelect" onChange={(e) => setYear(e.target.value)} width={150} value={year} style={{ width: '150px' }}>
              {years.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <select name="FilterSelect" onChange={(e) => setMonth(e.target.value)} width={150} value={month} style={{ width: '150px' }}>
              {months.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <div>
              <button className="upload-button" type="button" onClick={() => input.click()}>
                <div style={{ width: '200px' }}>
                  <strong>Selecionar arquivo</strong>
                </div>
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={handleSelect}
                  value=""
                  ref={(fileInput) => { input = fileInput }}
                />
              </button>
            </div>
          </div>
          <div style={{
            padding: '20px 20px', display: 'flex', flex: 1, width: '100%',
          }}
          >
            <div style={{
              display: 'flex', flex: 1, width: '100%',
            }}
            >
              <label className="custom-file-label" htmlFor="customFile">
                <strong style={{ color: '#2f3' }}>{archiveName}</strong>
              </label>
            </div>
            <div style={{
              display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end',
            }}
            >
              {
              archiveName !== ''
                ? (
                  <button className="upload-button" type="submit" disabled={!!uploadedFile}>
                    <div style={{ width: '100px' }}>
                      <strong>Upload</strong>
                    </div>
                  </button>
                ) : <div />
            }
            </div>
            <div style={{
              display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-start', marginLeft: '10px',
            }}
            >
              { uploadedFile
                ? (
                  <button className="upload-button" type="button" onClick={handleImport}>
                    <div style={{ width: '100px' }}>
                      <strong>Importar</strong>
                    </div>
                  </button>
                ) : <div />}
            </div>
          </div>
        </form>
        <div
          className="plusStyle"
          style={{
            height: message === '' ? '0px' : '40px', flex: 1, width: '100%', paddingRight: '20px', textAlign: 'center',
          }}
        >
          <b style={{ color: msgColor, fontSize: '14px' }}>{message}</b>
        </div>
        <div className="datatable" style={{ maxWidth: '700px', overflowY: 'auto', display: 'block' }}>
          <table>
            <tbody>
              <tr>
                <th>Arquivo</th>
                <th width={80} style={{ textAlign: 'center' }}>Tamanho</th>
                <th width={150} style={{ textAlign: 'center' }}>Data de criação</th>
                {/* <th width={100} style={{ textAlign: 'center' }}>Tipo</th> */}
                <th width={80} style={{ textAlign: 'center' }}>Mês</th>
                <th width={80} style={{ textAlign: 'center' }}>Versão</th>
              </tr>
              {renderRows()}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default Calendar
