import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../context/AuthContext'
import {
  FirstColumnStyle,
  SecondColumnStyle,
  BoxStyle,
  ThirdColumnStyle,
  LoginContainer,
  LogoStyle,
} from '../styles/login'
import {
  FormStyle,
  TitleStyle,
  ButtonStyle,
  InputStyle,
  LineStyle,
  StatusStyle,
} from '../styles/components'
import Prisma from '../../assets/icone_64.png'
import history from '../../routes/history'

const version = '3.83'

const Login = () => {
  const { authenticated, LogIn } = useContext(Context)
  const [agtLogin, setAgtLogin] = useState('')
  const [agtPassword, setAgtPassword] = useState('')
  const [status, setStatus] = useState('')

  const clearFields = () => {
    setAgtLogin('')
    setAgtPassword('')
  }
  // console.log(authenticated)

  const handleLogin = async (e) => {
    e.preventDefault()
    setStatus('Autenticando...')
    await LogIn(agtLogin, agtPassword)
  }

  useEffect(() => {
    if (authenticated) {
      history.push('/')
    }
  }, [authenticated])

  // useEffect(() => {
  //   if (!authenticated) {
  //     return <Redirect to="/login" />
  //   }
  //   console.log('Logado.')
  // }, [props])

  return (
    <LoginContainer name="LoginContainer">
      <FirstColumnStyle name="FirstColumnStyle">
        <></>
      </FirstColumnStyle>
      <SecondColumnStyle name="SecondColumnStyle">
        <LogoStyle name="LogoStyle">
          <img src={Prisma} alt="Prisma Network" />
        </LogoStyle>
        <BoxStyle onSubmit={handleLogin}>
          <FormStyle>
            <TitleStyle>Controle de acesso</TitleStyle>
            <LineStyle>
              <InputStyle
                type="input"
                placeholder="usuário"
                value={agtLogin || ''}
                onChange={(e) => setAgtLogin(e.target.value)}
              />
            </LineStyle>
            <LineStyle>
              <InputStyle
                type="password"
                placeholder="senha"
                value={agtPassword || ''}
                onChange={(e) => setAgtPassword(e.target.value)}
              />
            </LineStyle>
            <LineStyle>
              <ButtonStyle type="button" onClick={() => { clearFields() }}>Limpar</ButtonStyle>
              <ButtonStyle type="submit">Entrar</ButtonStyle>
            </LineStyle>
            <StatusStyle style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1,
            }}
            >
              {status && <div>{status}</div>}
              <div>{`versão ${version}`}</div>
            </StatusStyle>
          </FormStyle>
        </BoxStyle>
      </SecondColumnStyle>
      <ThirdColumnStyle name="ThirdColumnStyle">
        <></>
      </ThirdColumnStyle>
    </LoginContainer>
  )
}

export default Login
