/* eslint-disable no-unused-vars */
import React from 'react'
import lodash, { uniqueId } from 'lodash'
// import * as d3 from 'd3-array'
import './styles.css'

const Sales = ({ sellouts, lastday }) => {
  const rows = []
  let subtotal = 0
  sellouts.forEach((line, j) => {
    const columns = []
    subtotal += line.vendas
    let totalRow = 0
    for (let i = 1; i <= lastday; i += 1) {
      const sells = lodash.isNumber(line[i]) ? Math.round(parseFloat(line[i]), 2) : 0.0
      totalRow += sells
      columns.push(
        <div
          key={i}
          className="total"
          style={{ width: '70px', fontWeight: line[i] > 0 ? 'bold' : 'normal' }}
        >
          {sells}
        </div>,
      )
      // console.log(`dia: ${i}: ${line[i]}`)
    }
    // const sumSellout = d3.sum(sells, (d) => d.vendas)
    // const sumPonderado = d3.sum(sums, (d) => d.ponderado)

    const ponderado = lodash.isNumber(line.ponderado) ? Math.round(line.ponderado, 2) : 0.0
    // const total = lodash.isNumber(line.vendas) ? Math.round(line.vendas, 2) : 0.0
    // console.log('TOTAL: ', total)
    const row = (
      <div key={uniqueId()} className="row" style={{ backgroundColor: j % 2 ? '#111' : '#100' }}>
        <div className="column" style={{ width: '80px', textAlign: 'center' }}>{line.terCode}</div>
        <div className="column" style={{ width: '80px', textAlign: 'center' }}>{line.proSku}</div>
        <div className="column" style={{ width: '400px', textAlign: 'left' }}>{line.proDescription}</div>
        <div className="column" style={{ width: '300px', textAlign: 'left' }}>{line.proSkuPic}</div>
        <div className="column" style={{ width: '200px', textAlign: 'left' }}>{line.proEan}</div>
        <div className="column" style={{ width: '100px', textAlign: 'left' }}>{line.keyCalendar}</div>
        <div className="column" style={{ width: '80px', textAlign: 'left' }}>{line.calStartDay}</div>
        <div className="column" style={{ width: '80px', textAlign: 'left' }}>{line.calEndDay}</div>
        {columns}
        <div className="column" style={{ width: '80px' }}>{totalRow}</div>
        <div className="column" style={{ width: '80px' }}>{ponderado}</div>
      </div>
    )
    rows.push(row)
    // console.log(rows)
  })

  return (rows)
}

export default Sales
