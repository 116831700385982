import React, { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'
import { basename } from 'path'
// import { values } from 'lodash'

import { FullDate } from '../../../services/utils'
import api from '../../../services/api'
import apii from '../../../services/api_import'
import { years, months } from '../../../services/data'
import '../styles.css'

export default function Csv({ setLoading }) {
  const dt = new Date()
  const [status, setStatus] = useState('')

  const [year, setYear] = useState(dt.getFullYear().toString())
  const [month, setMonth] = useState(dt.getMonth().toString())
  const [market, setMarket] = useState([])

  const [keyCode, setKeyCode] = useState('')
  const [sellouts, setDbds] = useState([])
  const [filtered, setFiltered] = useState([])
  const [file, setFile] = useState('')
  const [terDisplay, setTerDisplay] = useState('')
  const [markets, setMarkets] = useState([])
  const [marketsOptions, setMarketsOptions] = useState([])
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState('   ')
  const [msgColor, setMsgColor] = useState('#000')
  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    setTimeout(() => {
      setMessage('')
    }, 1500)
  }

  const getMarkets = async () => {
    setShow(true)
    setLoading(true)
    const result = await api.get('dashboard/markets')
    const { data } = result
    setMarkets(data)
    const mkt = []
    data.map((key) => {
      mkt.push({
        label: key.keyDescription,
        value: key.keyCode,
      })
    })
    console.log(mkt)
    console.log(mkt[0])
    setMarketsOptions(mkt)
    setMarket(mkt[0])
    setKeyCode(mkt[0].value)
    setStatus('')
    console.log(markets)
    setShow(false)
    setLoading(false)
  }

  const handleMarket = (value) => {
    const aux = value.split(' - ')[0]
    console.log('Market: ', aux)
    setMarket(value)
    setKeyCode(aux)
  }

  const handleClear = () => {
    setTerDisplay('')
    setFile('')
    setFiltered(sellouts)
  }

  const handleFilter = async () => {
    popUp('Filtrando dados...', 'info')
    setShow(true)
    setLoading(true)
    console.log(year, month.padStart(2, '0'))
    const result = await apii.get(`imports/dbds?year=${year}&month=${month.padStart(2, '0')}`)
    const files = result.data
    console.log('result: ', files)
    const arquive = []
    if (files.length > 0) {
      files.forEach((arq) => {
        const name = basename(arq.Key)
        console.log(name)
        if (name.substring(0, 3) === 'DBD') {
          arquive.push({
            file: name,
            path: arq.Key.replace(name, ''),
            etag: arq.ETag,
            size: arq.Size,
            updatedAt: arq.LastModified,
          })
        }
      })
      console.log(arquive)
      setDbds(arquive)
      setFiltered(arquive)
      popUp(`${result.data.length} arquivos encontrados...`, 'success')
    } else {
      setDbds([])
      setFiltered([])
      popUp('Nenhum registro encontrado', 'warning')
    }
    setShow(false)
    setLoading(false)
  }

  const handleFilterFile = (value) => {
    setFile(value)
    const result = filtered.filter((sell) => sell._id.file.includes(value))
    setFiltered(result)
  }

  const handleGetFile = async (filekey) => {
    try {
      setLoading(true)
      const key = `${filekey.path}${filekey.file}`
      const Result = await apii.get(`imports/url?key=${key}`)
      console.log('handleGetFile: ', Result)
      const link = document.createElement('a')
      link.href = Result.data
      link.target = '_blank'
      link.download = filekey.file
      link.click()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  // const handleFilterTerDisplay = (value) => {
  //   setTerDisplay(value)
  // }

  // useEffect(() => {
  //   getMarkets()
  // }, [])

  return (
    <div className="container">
      <div className="headertitle">
        <div style={{ width: '400px' }}>Arquivos importados - DBD</div>
      </div>
      <div className="filters">
        <select name="FilterSelect" onChange={(e) => setYear(e.target.value)} value={year} style={{ width: '80px' }}>
          {years.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => setMonth(e.target.value)} value={month} style={{ width: '120px' }}>
          {months.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        {/* <select name="FilterSelect" placeholder="SELECIONE A REDE" onChange={(e) => handleMarket(e.target.value)} value={market} style={{ width: '300px' }}>
          {marketsOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select> */}
        <div style={{ textAlign: 'right' }}>
          <button type="button" onClick={handleFilter} className="buttonnav">Filtrar</button>
        </div>
      </div>
      <div className="datatable" style={{ height: 'calc(100vh - 350px)' }}>
        <div className="tableheader">
          <div style={{ width: '400px' }}>Arquivo</div>
          <div style={{ width: '200px' }}>Criado em</div>
          <div style={{ width: '100px' }}>Tamanho</div>
        </div>
        <div className="tablebody" style={{ height: 'calc(100vh - 350px)' }}>
          {
            filtered.map((sell) => (
              <div key={uniqueId()} className="renderRows">
                <div className="rows" style={{ textAlign: 'left', width: '400px' }}>
                  <button className="buttonDownload" type="button" style={{ width: '400px' }} onClick={() => handleGetFile(sell)}>{sell.file}</button>
                </div>
                <div className="rows" style={{ textAlign: 'left', width: '200px' }}>
                  {FullDate(sell.updatedAt)}
                </div>
                <div className="rows" style={{ textAlign: 'right', width: '100px' }}>
                  {sell.size}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>

  )
}
