import React, { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'
import api from '../../../services/api_import'

import '../styles.css'
import './styles.css'
import { years, months } from '../../../services/data'

export default function Neogrids() {
  const dt = new Date()

  const [markets, setMarkets] = useState([])
  const [selectedMarket] = useState([])
  const [allKas, setAllKas] = useState([])
  const [year, setYear] = useState(dt.getFullYear().toString())
  const [month, setMonth] = useState((dt.getMonth()))
  const [versions, setVersions] = useState([])
  const [version, setVersion] = useState('')
  const [terCode, setTerCode] = useState('')
  const [keyCode, setKeyCode] = useState('0')
  const [message, setMessage] = useState('')
  const [msgColor, setMsgColor] = useState('#000')

  const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez',
  ]
  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    setTimeout(() => {
      setMessage('')
    }, 1500)
  }

  const getMarkets = async (yy, mm) => {
    const result = await api.get(`sellouts/kas?year=${yy}&month=${mm}`)
    const { data } = result
    setMarkets(data)
    const all = {
      keyCode: '0',
      keyDescription: 'Todos',
      terDisplay: 'Todos',
    }
    console.log(all)
    // data.unshift(all)
    setAllKas(data)
  }

  const getVersions = async (mm) => {
    const result = await api.get(`imports/calendar?year=${year}&month=${mm}`)
    console.log(result)
    setVersions(result.data)
  }

  const downloadCSV = (csvStr, k, t, y, m) => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`
    hiddenElement.target = '_blank'
    const M = m.length < 2 ? `0${m}` : m
    const filename = `${y}_${M}_${k}_${t}.csv`
    hiddenElement.download = filename
    hiddenElement.click()
  }

  const gerarCsv = async () => {
    const data = {
      selYear: year,
      selMonth: parseInt(month, 10),
      keyCode,
      terCode,
      calVersion: version || '1',
    }
    try {
      // https://import.femsa.sellout.one/sellouts/byka?selYear=2020&selMonth=6&keyCode=193&terCode=2010
      console.log('DATA: ', data)
      // http://import/sellouts/byka
      const result = await api.post('sellouts/byka', data)
      console.log('SELLOUT: ', result)
      downloadCSV(result.data, keyCode, terCode, year, month)
    } catch (error) {
      popUp('Rede sem calendário', 'error')
    }
  }

  const gerarAllCsv = async (cb) => {
    const query = `sellout/csv/all?calMonth=${month}&calYear=${year}`
    console.log('query: ', query)
    await api.get(query, { responseType: 'blob' })
    const sellout = await api.get('files/sellout.csv', { responseType: 'blob' })

    const url = window.URL.createObjectURL(new Blob([sellout.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sellout.csv') // or any other extension
    document.body.appendChild(link)
    link.click()
    cb()
  }

  const handleCsv = async () => {
    if (keyCode === '0') {
      gerarAllCsv((err, result) => {
        if (err) {
          console.log('gerarAllCsv error: ', err)
        }
        console.log('gerarAllCsv:', result)
        // downloadCSV(result, 'all', terCode, year, month)
      })
    } else {
      console.log('gerarCsv')
      gerarCsv()
    }
  }

  const handleMarket = (ka) => {
    setKeyCode(ka)
    const selected = markets.filter((item) => item.keyCode === ka)
    const parts = selected[0].terDisplay.split(' - ')
    setTerCode(parts[0])
  }

  const handleMonth = (mm) => {
    console.log(mm)
    setMonth(mm)
    getMarkets(year, mm)
    const filtered = monthNames[mm - 1]
    console.log(filtered)
    getVersions(filtered)
  }

  const renderRows = () => {

  }

  useEffect(() => {
    getMarkets(year, month - 1)
    console.log(month - 1)
    console.log(monthNames[month - 1])
    getVersions(monthNames[month - 1])
  }, [year, month, getVersions, monthNames])

  return (
    <div className="container">

      <div className="filters">
        <select name="FilterSelect" onChange={(e) => setYear(e.target.value)} value={year} style={{ width: '80px' }}>
          {years.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => handleMonth(e.target.value)} value={month} style={{ width: '120px' }}>
          {months.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => setVersion(e.target.value)} value={version} style={{ width: '80px' }}>
          {versions.map((item) => (
            <option key={item.calVersion} value={item.calVersion}>
              {`v. ${item.calVersion}`}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => handleMarket(e.target.value)} value={selectedMarket} style={{ width: '300px' }}>
          {allKas.map((item) => (
            <option key={uniqueId()} value={item._id}>
              {`${item.keyDescription} | ${item.terDisplay}`}
            </option>
          ))}
        </select>
      </div>
      <div className="buttons">
        <button type="button" onClick={handleCsv}>Gerar CSV</button>
      </div>
      <div className="plusStyle" style={{ height: message === '' ? '0px' : '40px' }}>
        <b style={{ color: msgColor }}>{message}</b>
      </div>
      <div className="datatable">
        <table>
          <tbody>
            <tr>
              <th>Rede</th>
              <th width={300} style={{ textAlign: 'center' }}>Sellout</th>
            </tr>
            {renderRows()}
          </tbody>
        </table>
      </div>
    </div>
  )
}
