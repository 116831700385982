import styled from 'styled-components'

export const HeaderStyle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background: #000;
  height: 80px;
  border-bottom: 1px solid #2E313B;
`
export const LogoStyle = styled.div`
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  flex: 1;
  margin-right: 15px;
`
export const MidStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
export const EndStyle = styled.div`
  /* flex: 2; */
  justify-content: flex-end;
  align-items: center;
`
