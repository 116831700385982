import styled from 'styled-components'

export const FirstColumnStyle = styled.div`
  flex: 2;
  height: 240px;
  background: #000;
`
export const SecondColumnStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #000;
  width: 100%;
  height: 240px;
  flex: 1;
`
export const ThirdColumnStyle = styled.div`
  flex: 2;
  height: 240px;
  background: #000;
`
export const LogoStyle = styled.div`
justify-content: flex-end;
align-items: flex-end;
width: 100%;
display: flex;
flex: 1;
margin-right: 15px;
`
export const BoxStyle = styled.form`
flex: 2;
border-left: 5px solid maroon;
padding: 20px;
margin: 10px;
`
export const LoginContainer = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
