module.exports = {
  FullDate: (date) => {
    const dt = new Date(date)
    const y = dt.getFullYear().toString()
    let m = (dt.getMonth() + 1).toString()
    let d = dt.getDate().toString()
    let hh = dt.getHours().toString()
    let mm = dt.getMinutes().toString()
    if (d.length === 1) d = `0${d}`
    if (m.length === 1) m = `0${m}`
    if (hh.length === 1) hh = `0${hh}`
    if (mm.length === 1) mm = `0${mm}`
    return `${d}/${m}/${y} ${hh}:${mm}`
  },

  GetDate: (dt) => {
    const timestamp = Date.now(dt) // This would be the timestamp you want to format
    return new Intl.DateTimeFormat('pt-br', {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
    }).format(timestamp)
  },

}
