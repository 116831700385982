import React, { useContext } from 'react'
import { Context } from '../context/AuthContext'

import Main from '../pages/main'
import Login from '../pages/login'

const Routes = () => {
  const { authenticated } = useContext(Context)

  return authenticated ? <Main /> : <Login />
}

export default Routes
