import React, { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'
import './styles.css'
import '../styles.css'
import apio from '../../../services/api_import'

const url = 'https://omnichannel.sfo2.digitaloceanspaces.com/Femsa/sellouts'

export default function Consolidado() {
  const [sellouts, setSellouts] = useState([])
  const [message, setMessage] = useState('   ')
  const [msgColor, setMsgColor] = useState('#000')
  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    setTimeout(() => {
      setMessage('')
    }, 1500)
  }

  useEffect(() => {
    const getSellouts = async () => {
      popUp('Buscando sellouts..', 'info')
      const result = await apio.get('sellouts/consolidados')
      console.log(result)
      setSellouts(result.data)
      if (result.data.length === 0) {
        popUp('Nenhum registro encontrado', 'warning')
      } else {
        popUp('Dados encontrados...', 'success')
      }
    }
    getSellouts()
  }, [])

  return (
    <div className="container">
      <div className="plusStyle" style={{ height: message === '' ? '0px' : '40px' }}>
        <b style={{ color: msgColor }}>{message}</b>
      </div>

      <div className="datatable">
        <div className="tableheader">
          <div style={{ width: '300px' }}>Rede</div>
          <div style={{ width: '300px' }}>Sellout</div>
        </div>
        <div className="tablebody">
          {
            sellouts.map((sell) => (
              <div key={uniqueId()} className="renderRows">
                <div className="rows" style={{ textAlign: 'left', width: '300px' }}>
                  {sell.keyDescription}
                </div>
                <div className="rows" style={{ textAlign: 'left', width: '300px' }}>
                  <a href={`${url}/${sell.file}`} alt={sell.file} download rel="noopener noreferrer" target="_blank" type="application/octet-stream">
                    {sell.file}
                  </a>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
