import React, { createContext, useState, useEffect } from 'react'

import api from '../services/api'

const Context = createContext()

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false)
  const [agent, setAgent] = useState([])

  const LogOut = () => {
    localStorage.removeItem('currentAgent')
    localStorage.removeItem('token')
    setAuthenticated(false)
    setAgent([])
  }

  const LogIn = async (login, pass) => {
    const payload = {
      agtLogin: login,
      agtPassword: pass,
    }
    const { data } = await api.post('/auth', payload)
    localStorage.setItem('currentAgent', JSON.stringify(data.agent))
    localStorage.setItem('token', JSON.stringify(data.token))
    await setAuthenticated(true)
    await setAgent(data.agent)
  }

  useEffect(() => {
    const check = async () => {
      const token = await localStorage.getItem('token')
      const currentAgent = await localStorage.getItem('currentAgent')
      setAuthenticated(!!token)
      setAgent(currentAgent)
    }
    check()
  }, [])

  return (
    <Context.Provider value={{
      authenticated,
      setAuthenticated,
      agent,
      LogIn,
      LogOut,
    }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, AuthProvider }
