import React, { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'
// import { values } from 'lodash'

import apio from '../../../services/api_import'
import coke from '../../../assets/dancingCoke.gif'
import { years, months, days } from '../../../services/data'
import '../styles.css'

export default function Reports() {
  const dt = new Date()
  const [status, setStatus] = useState('')

  const [year, setYear] = useState(dt.getFullYear())
  const [month, setMonth] = useState(dt.getMonth())
  const [day, setDay] = useState(1)
  const [market, setMarket] = useState([])

  const [keyCode, setKeyCode] = useState('')
  const [selLoja, setSelLoja] = useState('')
  const [sellouts, setSellouts] = useState([])
  const [filtered, setFiltered] = useState([])
  const [file, setFile] = useState('')
  const [totalRegister, setTotalRegister] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [terDisplay, setTerDisplay] = useState('')
  const [markets, setMarkets] = useState([])
  const [marketsOptions, setMarketsOptions] = useState([])
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState('   ')
  const [msgColor, setMsgColor] = useState('#000')
  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    setTimeout(() => {
      setMessage('')
    }, 1500)
  }

  const handleMarket = (value) => {
    const aux = value.split(' - ')[0]
    console.log('Market: ', aux)
    setMarket(value)
    setKeyCode(aux)
  }

  const handleClear = () => {
    setSelLoja('')
    setTerDisplay('')
    setFile('')
    setFiltered(sellouts)
  }

  const handleFilter = async () => {
    popUp('Filtrando dados...', 'info')
    setShow(true)
    const result = await apio.get(`files/sellouts?selYear=${year}&selMonth=${month}&selDay=${day}&keyCode=${keyCode}`)
    console.log('result: ', result)
    setSellouts(result.data)
    setFiltered(result.data)
    if (result.data.length === 0) {
      popUp('Nenhum registro encontrado', 'warning')
    } else {
      popUp('Dados encontrados...', 'success')
    }
    setShow(false)
  }

  const handleFilterLoja = (value) => {
    console.log(value)
    setSelLoja(value)
    if (filtered.length > 0) {
      const result = filtered.filter((sell) => sell._id.selLoja.toString().includes(value))
      setFiltered(result)
    }
  }

  const handleFilterFile = (value) => {
    console.log(value)
    setFile(value)
    if (filtered.length > 0) {
      const result = filtered.filter((sell) => sell.proEan.toString().includes(value))
      setFiltered(result)
    }
  }

  const handleFilterTerDisplay = (value) => {
    console.log(value)
    setTerDisplay(value)
    if (filtered.length > 0) {
      const result = filtered.filter((sell) => sell.selQuantity >= parseInt(value, 10))
      setFiltered(result)
    }
  }

  useEffect(() => {
    const Summarize = () => {
      let totalReg = 0
      let totalQty = 0
      filtered.forEach((sell) => {
        totalQty += sell.selQuantity
        totalReg += sell.registers
      })
      setTotalQuantity(totalQty)
      setTotalRegister(totalReg)
      setTotalRows(filtered.length)
    }
    Summarize()
  }, [filtered])

  useEffect(() => {
    const getMarkets = async () => {
      setShow(true)
      const result = await apio.get('files/markets')
      const { data } = result
      setMarkets(data)
      const mkt = []
      data.forEach((key) => {
        mkt.push({
          label: key.keyDescription,
          value: key.keyCode,
        })
      })
      console.log(mkt)
      setMarketsOptions(mkt)
      setMarket(mkt[0])
      setKeyCode(mkt[0].value)
      setStatus('')
      console.log(markets)
      setShow(false)
    }
    getMarkets()
  }, [markets])

  return (
    <div className="container">
      <div className="headertitle">
        <div style={{ width: '400px' }}>REPORTS - Consulta de vendas</div>
        <div className="waiting">
          {
            show && (
              <div className="boardgif">
                <img src={coke} alt="loading..." height={60} />
                <p style={{ paddingLeft: '20px' }}>{`...${status}`}</p>
              </div>
            )
          }
        </div>
      </div>
      <div className="filters">
        <select name="FilterSelect" onChange={(e) => setYear(e.target.value)} value={year} style={{ width: '80px' }}>
          {years.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => setMonth(e.target.value)} value={month} style={{ width: '120px' }}>
          {months.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => setDay(e.target.value)} value={day} style={{ width: '80px' }}>
          {days.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select name="FilterSelect" onChange={(e) => handleMarket(e.target.value)} value={market} style={{ width: '300px' }}>
          {marketsOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <div style={{ textAlign: 'right' }}>
          <button type="button" onClick={handleFilter} className="buttonnav">Filtrar</button>
        </div>
      </div>
      <div className="datatable" style={{ height: 'calc(100vh - 350px)' }}>
        <div className="filterheader">
          <div style={{ marginRight: '5px' }}>
            <input value={selLoja} onChange={(e) => handleFilterLoja(e.target.value)} className="searchInput" placeholder="Procurar por Loja" />
          </div>
          <div style={{ marginRight: '5px' }}>
            <input value={file} onChange={(e) => handleFilterFile(e.target.value)} className="searchInput" placeholder="Procurar por EAN" />
          </div>
          <div style={{ marginRight: '5px' }}>
            <input value={terDisplay} onChange={(e) => handleFilterTerDisplay(e.target.value)} className="searchInput" placeholder="Procurar quantidade" />
          </div>
          <div style={{ textAlign: 'right' }}>
            <button type="button" onClick={handleClear} className="buttonnav">Limpar</button>
          </div>
          <div className="plusStyle" style={{ height: message === '' ? '0px' : '40px' }}>
            <b style={{ color: msgColor }}>{message}</b>
          </div>
        </div>
        <div className="tableheader" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{`Linhas encontradas: ${totalRows}`}</div>
          <div>{`Total de vendas: ${totalQuantity}`}</div>
          <div>{`Total de registros: ${totalRegister}`}</div>
        </div>
        <div className="tableheader">
          <div style={{ width: '100px' }}>Loja</div>
          <div style={{ width: '300px' }}>EAN</div>
          <div style={{ width: '100px' }}>Vendas</div>
          <div style={{ width: '100px' }}>Registros</div>
        </div>
        <div className="tablebody" style={{ height: 'calc(100vh - 350px)' }}>
          {
            filtered.map((sell) => (
              <div key={uniqueId()} className="renderRows">
                <div className="rows" style={{ textAlign: 'center', width: '100px' }}>
                  {sell._id.selLoja}
                </div>
                <div className="rows" style={{ textAlign: 'left', width: '300px' }}>
                  {sell.proEan}
                </div>
                <div className="rows" style={{ textAlign: 'right', width: '100px' }}>
                  {sell.selQuantity}
                </div>
                <div className="rows" style={{ textAlign: 'right', width: '100px' }}>
                  {sell.registers}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>

  )
}
