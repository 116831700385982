import React, { useState } from 'react'

import Summary from './components/summary'

export default function Dashboard({ setLoading, selectedMenu, isOpen }) {
  const [msgColor, setMsgColor] = useState('#000')
  const [message, setMessage] = useState('')
  // const [selectedType, setSelectedType] = useState('totais')
  // const [show, setShow] = useState(false)

  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
  }
  return (
    <div className="container">
      <div className="headertitle">
        <div style={{ width: '400px', color: selectedMenu ? selectedMenu.color : '#eee555' }}>Dashboard</div>
        <div className="plusStyle" style={{ height: message === '' ? '0px' : '40px' }}>
          <b style={{ color: msgColor }}>{message}</b>
        </div>
      </div>
      <div className="typesellout">
        <Summary setLoading={setLoading} popUp={popUp} isOpen={isOpen} />
      </div>
    </div>
  )
}
