import React from 'react'
import './code.txt'
import sellout1 from '../../../assets/sellout1.png'
import sellout2 from '../../../assets/sellout2.png'
import sellout3 from '../../../assets/sellout3.png'

const Rules = () => (
  <div className="container">
    <div className="headertitle" style={{ width: '100%' }}>
      <div style={{ width: '50%' }}>
        <p>Regras</p>
      </div>
    </div>
    <div style={{ width: '100%', overflow: 'auto' }}>
      <p
        disabled
        style={{
          width: '100%', padding: '15px', backgroundColor: '#333', color: '#fafafa',
        }}
      >
        REGRA DE PONDERAÇÃO

      </p>
      <img src={sellout1} alt="page1" width={800} />
      <img src={sellout2} alt="page2" width={800} />
      <img src={sellout3} alt="page3" width={800} />
    </div>
    {/* <button type="button" onClick={handleKeyAccounts}>Redes</button> */}
  </div>
)

export default Rules
