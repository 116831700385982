import styled from 'styled-components'

export const FormStyle = styled.div`
  display: flex;
  flex-direction: column;
`
export const TitleStyle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fafafa;
  padding-bottom: 10px;
`
export const StatusStyle = styled.div`
  padding-left: 10px;
  font-size: 14px;
  color: red;
`
export const LineStyle = styled.div`
padding: 5px;
display: flex;
justify-content: flex-start;
align-items: center;
`
export const ButtonStyle = styled.button`
width: 110px;
height: 24px;
font-size: 14px;
border: 1px solid #1F2228;
background: #1F2228;
margin: 3px;
margin-right: 15px;
color: #434548;
`
export const InputStyle = styled.input`
width: 240px;
height: 24px;
background: #1F2228;
border: 1px solid #1F2228;
margin: 3px;
padding: 10px;
font-size: 14px;
color: #f5f5f5;
`
export const CircleStyle = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #000;
  color: #1F2228;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px;
`
export const ClearButton = styled.button`
  border: none;
  background: #000;
  color: #f5f5f5;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: 'row';
  flex: 1;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #1F2228;
  padding-right: 20px;
`
