import React, { useState, useEffect } from 'react'

import '../styles.css'
import Consolidado from './consolidado'
import Neogrids from './neogrids'
import Totais from './totais'

export default function Sellouts({ setLoading }) {
  const [selectedType] = useState('totais')
  const [msgColor, setMsgColor] = useState('#000')
  const [message, setMessage] = useState('')
  const [logs, setLogs] = useState([])

  const popUp = (msg, type) => {
    switch (type) {
      case 'error':
        setMsgColor('#D03B43')
        break
      case 'info':
        setMsgColor('#008EE3')
        break
      case 'warning':
        setMsgColor('#FFAA28')
        break
      case 'success':
        setMsgColor('#2f3')
        break
      default:
        break
    }
    setMessage(msg)
    logs.unshift({ message: msg })
    setLogs(logs)
    // console.log(logs)
  }
  const loadTypes = () => {
    switch (selectedType) {
      case 'neogrids':
        return <Neogrids />
      case 'consolidado':
        return <Consolidado />
      case 'totais':
        return <Totais setLoading={setLoading} popUp={popUp} logs={logs} />
      default:
        return <Totais setLoading={setLoading} popUp={popUp} />
    }
  }

  useEffect(() => {
    const firstLoadTypes = () => {
      switch (selectedType) {
        case 'neogrids':
          return <Neogrids />
        case 'consolidado':
          return <Consolidado />
        case 'totais':
          return <Totais setLoading={setLoading} />
        default:
          return <Totais setLoading={setLoading} />
      }
    }
    firstLoadTypes()
  }, [selectedType, setLoading])

  return (
    <div className="container">
      <div className="headertitle">
        <div style={{ width: '400px' }}>SELLOUTS</div>
        <div className="plusStyle" style={{ height: message === '' ? '0px' : '40px' }}>
          <b style={{ color: msgColor }}>{message}</b>
        </div>
      </div>
      <div className="typesellout">
        {loadTypes()}
      </div>
    </div>
  )
}
